<template>
    <div class="show_box">
        <PrivateShow/>
        

    </div>
</template>
<script>
import PrivateShow from "../../../components/interactiveManagement/PrivateMessagement/PrivateShow.vue"
export default{
    data(){
        return {
            classType:[
                {
                    name:"全部",
                    id:1
                
                },
                {
                    name:"朋友私信",
                    id:2
                
                },
                {
                    name:"陌生人私信",
                    id:3
                
                },
                {
                    name:"群消息",
                    id:4
                },
            ]

        }
    },
    components:{
        PrivateShow
    }
}


</script>
<style lang="scss" scoped>
.show_box{
    width: 100%;
    height: 100%;
  
}
</style>