<template>
    <div class="privateMesasge_box">
        <div class="header">
            <div class="header_left">
                <div class="type_item" v-for="(item, index) in classType"
                    :class="activeTypeIndex == index ? 'activeType' : ''" @click="activeType(index)">
                    {{ item.name }}

                </div>
            </div>
            <div class="allSelectBtn">
                全选 <el-checkbox key="1"></el-checkbox>
            </div>

        </div>
        <div class="table_box">
            <ul class="table_ul">
                <li v-for=" (item, index) in messageData">
                    <div class="li_left">
                        <div class="image_box">
                            <img :src="item.imgUrl" alt="">
                            <div class="circle_tip">1</div>
                        </div>
                        <div class="message_box">
                            <p>{{ item.name }}</p>
                            <p class="message">{{ item.message }}</p>

                        </div>

                    </div>
                    <div class="li_right">
                        <div class="timer">{{ item.date }}</div>
                        <div class="operation">
                            <div class="operation_box">
                                <button>置顶</button>
                                <button>已读</button>
                                <button style="color: rgb(255,79,38);">删除</button>
                            </div>
                            <el-checkbox :key="item.isSelect"></el-checkbox>


                        </div>

                    </div>
                </li>
            </ul>

        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
            classType: [
                {
                    name: "全部",
                    id: 1

                },
                {
                    name: "朋友私信",
                    id: 2

                },
                {
                    name: "陌生人私信",
                    id: 3

                },
                {
                    name: "群消息",
                    id: 4
                },
            ],
            activeTypeIndex: 0,
            messageData: [
                {
                    imgUrl: require("../../../assets/img/image/blank_headPic.png"),
                    name: "北极星",
                    isZhiding: true,
                    xiaoxi: 2,
                    isRead: true,
                    date: '12-06',
                    isSelect: false,
                    id: 1,
                    message: "你收到一条新消息"
                },
                {
                    imgUrl: require("../../../assets/img/image/blank_headPic.png"),
                    name: "萝北哈哈",
                    isZhiding: true,
                    xiaoxi: 2,
                    isRead: true,
                    date: '12-06',
                    isSelect: false,
                    id: 1,
                    message: "分享[视频]"
                },
                {
                    imgUrl: require("../../../assets/img/image/blank_headPic.png"),
                    name: "三只小猪",
                    isZhiding: true,
                    xiaoxi: 5,
                    isRead: true,
                    date: '12-08',
                    isSelect: false,
                    id: 1,
                    message: "哈哈哈哈哈"
                },
            ]

        }
    },
    methods: {
        activeType(index) {
            this.activeTypeIndex = index

        }
    }
}


</script>
<style lang="scss" scoped>
.privateMesasge_box {
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 53px;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(242, 242, 244);

        .header_left {

            width: 100%;
            display: flex;
            align-items: center;

            .type_item {
                padding: 16px 4px 14px;
                border-bottom: 2px solid transparent;
                margin-right: 20px;
            }

            .activeType {
                border-bottom: 2px solid rgb(254, 44, 85);
            }


        }


    }

    .allSelectBtn {
        width: 70px;

        .el-checkbox {

            margin-left: 3px;
        }

        ::v-deep .el-checkbox__inner {
            width: 18px;
            height: 18px;

            &:hover {
                border-color: pink !important;
            }
        }

        ::v-deep {
            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: pink;
                border: 1px solid pink;

                &::after {
                    height: 10px;
                    left: 5px;
                    width: 5px;
                }


            }
        }
    }

    .table_box {


        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        height: calc(100vh - 300px);
        // background-color: skyblue;
        overflow-y: auto;

        .table_ul {
            width: 100%;
            height: 100%;
            text-decoration: none;

            li {
                width: 100%;
                display: inline-block;
                height: 108px;
                padding: 30px 16px 30px 8px;
                box-sizing: border-box;
                display: flex;

                .li_left {
                    width: 0px;
                    flex: 1;
                    // background-color: skyblue;
                    display: flex;
                    align-items: center;

                    .image_box {
                        width: 48px;
                        height: 48px;
                        border-radius: 100%;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                        }

                        .circle_tip {
                            min-width: 18px;
                            height: 18px;
                            border-radius: 100%;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background-color: rgb(254, 44, 85);
                            color: #fff;
                            text-align: center;
                            line-height: 18px;
                        }
                    }

                    .message_box {
                        height: 100%;
                        padding: 2px;
                        margin-left: 10px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        p {
                            margin: 0;
                            padding: 0;
                        }

                        .message {
                            color: #999;
                        }
                    }
                }

                .li_right {
                    width: 200px;
                    height: 100%;
                    // background-color: sandybrown;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: relative;

                    .timer {
                        font-size: 12px;
                        color: #999;
                        display: inline-block;


                    }

                    .operation {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        // background-color: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;



                        .operation_box {
                            display: none;
                            border-left: 1px solid #eceaea;
                            padding-left: 10px;

                            button {
                                padding: 6px 10px;
                                border-radius: 4px;
                                color: rgb(92, 93, 102);
                                box-shadow: none;
                                font-size: 14px;
                                border: none;
                                font-weight: 600;
                                background-color: transparent;
                                font-family: -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;

                                &:hover {
                                    background-color: rgb(228, 228, 230);
                                }
                            }
                        }

                        .el-checkbox {
                            display: none;
                            margin-top: 1px;
                        }

                        ::v-deep .el-checkbox__inner {
                            width: 18px;
                            height: 18px;

                            &:hover {
                                border-color: pink !important;
                            }
                        }

                        ::v-deep {
                            .el-checkbox__input.is-checked .el-checkbox__inner {
                                background-color: pink;
                                border: 1px solid pink;

                                &::after {
                                    height: 10px;
                                    left: 5px;
                                    width: 5px;
                                }


                            }
                        }
                    }

                    &:hover {
                        .timer {
                            display: none;
                        }

                        .operation {
                            .operation_box {
                                display: block;
                            }

                            .el-checkbox {
                                display: block;
                            }

                        }

                    }
                }
            }
        }
    }

}
</style>